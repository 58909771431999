<template>
  <v-container>
    <div class="pa-8 grey lighten-5 rounded-lg">
      <v-row justify="end" align="end">
        <v-col cols="12" md="8" class="py-8">
          <div class="text-h5 secondary--text font-weight-medium mb-5">My Business</div>
          <div class="text-over line text--secondary font-weight-medium mt-10">Total Business</div>
          <div class="text-h5 primary--text">{{ myBusiness.length || 0 }}</div>
        </v-col>
        <v-col class="text-right business-bg-img" cols="12" md="4" style="height: 200px">
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-10">
      <v-col cols="12" md="4">
        <create-business />
      </v-col>
    </v-row>
    <v-row class="my-8 pa-0 pa-md-5 grey lighten-5 rounded-lg" v-if="myBusiness">
      <v-col cols="12" v-if="bizload">
        <div class="py-15 text-center my-15">
          <mini-loader class="text-center"></mini-loader>
        </div>
      </v-col>
      <template v-else>
        <template v-if="myBusiness.length != 0">
          <v-col cols="12" md="6" v-for="(i, index) in myBusiness" :key="index">
            <router-link :to="{ name : 'SelectedBusiness', params: { id : i.id }}">
              <v-card class="" elevation="0">
                <v-card
                  flat
                  class="each-business primary rounded-lg lighten-1 pa-4"
                >
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-icon color="white" size="64">mdi-briefcase-account-outline</v-icon>
                    </v-col>
                    <v-col cols="12" md="10">
                      <p class="head mb-0 white--text">{{ i.name }}</p>
                      <div class="text-overline secondary--text">{{ i.category }}</div>
                      <p class="address mb-0 white--text">{{ i.location }}</p>
                      <!--p class="balance mb-0 white--text text-body-1">Amount: <span class="text-h6">&#8358;****.**</span></p-->
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </router-link>
          </v-col>
        </template>
        <template v-else>
          <template v-if="neterror">
            <v-col cols="12">
              <div class="py-15 text-center text--secondary my-15">
                Something went wrong, check your network settings.
              </div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <div class="py-15 text-center text--secondary my-15">
                You have not registered any Business.
              </div>
            </v-col>
          </template>
        </template>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CreateBusiness from '@/components/business/CreateBusiness.vue'
import PincodeInput from 'vue-pincode-input'
import MiniLoader from '@/components/MiniLoader.vue'

export default {
  layout: 'dashboard',
  components: {
    CreateBusiness,
    PincodeInput,
    MiniLoader
  },
  data() {
    return {
      myBusiness: [],
      dialog: false,
      singleBusiness: [],
      email: '',
      form: { pin: '' },
      valid: true,
      loading: false,
      business_id: '',
      description: '',
      bizload: true,
      neterror: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.getMyBusiness()
  },
  methods: {
    ...mapActions('business', ['mybusinesses']),
    
    async getMyBusiness() {
      try {
        let response = await this.mybusinesses()
        this.myBusiness = response.data
        this.bizload = false
      } catch (error) {
          this.bizload = false
          this.neterror = true
          if(error.response && error.response.status == 404) {
            this.$toast.error(error.response.data.message ?
              error.response.data.message :
              ''
            )
          } else {
              this.$toast.error(error.response.data.message);
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.card-overlay {
  background: linear-gradient(
    292.83deg,
    #171b70 41.23%,
    rgba(193, 161, 61, 0.64) 100%
  ) !important;
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #ffffff;
  }
  h5 {
    font-size: 40px;
  }
}
.business-card {
  background: #ffffff;
  border: 0.5px solid #e2c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #171b70;
  }
  .each-business {
    background: #ffffff;
    border: 1px solid #171b70;
    box-sizing: border-box;
    border-radius: 4px;
    p.head {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;

      color: #2c2a2a;
    }
    p.address {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      color: #c1a13d;
    }
    p.balance {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #7a7a7a;
      span {
        color: #4b4949;
      }
    }
  }
}
.business-details {
  background: linear-gradient(
    302.27deg,
    #282c7c 37.52%,
    rgba(193, 161, 61, 0.91) 96.05%
  ) !important;
  border-radius: 6px;
  background-image: url('../../../static/assets/business-details.svg');
  p {
    font-size: 14px;
  }
  p.small {
    font-size: 12px;
  }
  h3 {
    font-size: 25px;
  }
}

a {
  text-decoration: none !important;
}

.business-bg-img{
  background-image: url('../../../assets/icons/business.png');
  background-repeat: no-repeat;
  background-position: center;
}
p.address {
  font-size: 14px;
  color: #7a7a7a;
}
</style>
