<template>
  <div>
    <v-card class="pa-1 rounded-lg text-center" outlined @click="dialog = true">
      <div class="grey lighten-5 pa-3 pa-sm-8">
        <div><v-icon dark color="primary">mdi-plus</v-icon></div>
        <div class="text-subtitle-1 font-weight-bold primary--text">Create Busines/Organisation</div>
      </div>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card class="form-colored-bg business-card pa-6">
        <p class="head">Create Business</p>
        <p class="small mb-8">Create a  business wallet to manage your business and Organisations</p>
        <p class="label mb-2">Business Name</p>
        <v-text-field outlined v-model="form.name" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <v-form lazy-validation ref="form" v-model="valid">
        <p class="label mb-2">Select business category</p>
        <v-select outlined v-model="form.category" :items="industries" required :rules="[v=>!!v || 'This field is required']" >
        </v-select>
        <p class="label mb-2">Description of Business</p>
        <v-text-field outlined v-model="form.description" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <p class="label mb-2">Business Location</p>
        <v-text-field outlined v-model="form.location" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <p class="label mb-2">Country</p>
        <v-text-field outlined v-model="form.country" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <p class="label mb-2">State</p>
        <v-select outlined v-model="form.state" :items="states" item-text="name" required :rules="[v=>!!v || 'This field is required']"></v-select>
          <p class="label mb-2">City</p>
        <v-text-field outlined v-model="form.city" required :rules="[v=>!!v || 'This field is required']"></v-text-field>
        <v-btn class="primary" :loading="loading" block large @click="$refs.form.validate() ? createBusiness() : null">Create</v-btn>
        <v-btn class="mt-3" depressed large @click="dialog = !dialog" block>Cancel</v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { states } from '@/util/statelga'
import {mapGetters, mapActions} from 'vuex'
import {industries} from '@/util/industries'
export default {

  data () {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {},
      states: states,
      industries: industries,
      bizload: true,
      neterror: false,
      myBusiness: [],
    }
  },
  methods: {
    ...mapActions('business', ['create', 'mybusinesses']),

    async createBusiness () {
      this.loading = true
      await this.create(this.form).then(response => {
        this.$toast.success(response.message)
        this.loading = this.dialog = false
        this.$refs.form.reset()
      }).catch(error => {
        if(error.response && error.response.status === 422) {
					this.$toast.error(
						error.response.data.name ?
						error.response.data.name[0] :
						''
					)
				} else {
          this.$toast.error(error.response.data.message)
        }
        this.loading  = false
      })
    },

    async getMyBusiness() {
      try {
        let response = await this.mybusinesses()
        this.myBusiness = response.data
        this.bizload = false
      } catch (error) {
          this.bizload = false
          this.neterror = true
          if(error.response && error.response.status == 404) {
            this.$toast.error(error.response.data.message ?
              error.response.data.message :
              ''
            )
          } else {
              this.$toast.error(error.response.data.message);
        }
      }
    }
  },
  mounted() {
    this.form.user_id = this.user.id
  },
  computed : {
    ...mapGetters('auth', ['user'])
  }
}
</script>
<style lang="scss" scoped>
p.head{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #171B70;


}
p.small{
  font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
/* identical to box height */

display: flex;
align-items: center;

color: #7A7A7A;


}
</style>
